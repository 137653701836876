.option-mobile-nav{
  height: 97% !important;
}
.test {
  transition: all 0.5s ease;
  cursor: pointer !important;
}
.small-nav {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.carousel {
  width:815px;
  height:500px;
  margin: auto;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  display: none !important;
}


/* ANIMACION DE LOGOS  */

.bk-theme-2-change{
  z-index: -1;
    position: absolute;
    top: 0px;
    width: 100%;
    /* left: 0; */
    /* display: flex; */
    /* justify-content: center; */
    /* height: 500px; */
    /* overflow: hidden; */
}


.mover {
  padding-right: 100%;
  z-index:-1 !important;
}
.content_img {
  width: 100%;
  /* background-color: red; */
  position: absolute;
  height: 458px;
  top: 0;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation-name: moveLeft;
  animation-name: moveLeft;
  -webkit-animation-duration: 35s;
  animation-duration: 35s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.content_img_2 {
  width: 100%;
  /* background-color: blue; */
  height: 458px;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-animation-name: moveLeft_1;
  animation-name: moveLeft_1;
  -webkit-animation-duration: 35s;
  animation-duration: 35s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes moveLeft {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

@keyframes moveLeft {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

@-webkit-keyframes moveLeft_1 {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes moveLeft_1 {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.breathing_img {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-animation-name: breathing_img;
  animation-name: breathing_img; }

.breathing_img_1 {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-animation-name: breathing_img_1;
  animation-name: breathing_img_1; }

.breathing_img,
.breathing_img_1 {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-animation-name: breathing_img;
  animation-name: breathing_img;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes breathing_img {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes breathing_img {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes breathing_img_1 {
  0% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }

@keyframes breathing_img_1 {
  0% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }


    .img-circle-1,
.img-circle-2,
.img-circle-3,
.img-circle-4,
.img-circle-5,
.img-circle-6,
.img-circle-7,
.img-circle-8,
.img-circle-9,
.img-circle-10,
.img-circle-11,
.img-circle-12,
.img-circle-13{
  position: absolute;
  z-index: 10;
}

.img-circle-1{
  /* width: 68px; */
  left: 7px;
  bottom: 47px;
}
/* Tamaños imagenes */
.img-circle-1,
.img-circle-2,
.img-circle-3,
.img-circle-5,
.img-circle-6,
.img-circle-8,
.img-circle-11{
  width: 133px;
}

.img-circle-2{
  left: 130px;
  top: 140px;
}
.img-circle-3{
  left: 285px;
  bottom: 20px;
}
.img-circle-4{
  width: 94px;
  left: 360px;
  top: 164px;
}
.img-circle-5{
  /* width: 87px; */
  left: 470px;
  top: 100px;
}
.img-circle-6{
  /* width: 68px; */
  left: 530px;
  top: 275px;
}
.img-circle-7{
  width: 129px;
  right: 515px;
  top: 150px;
}
.img-circle-8{
  /* width: 99px; */
  right: 475px;
  top: 325px;
}
.img-circle-9{
  width: 128px;
  right: 355px;
  top: 190px;
}
.img-circle-10{
  width: 149px;
  right: 250px;
  top: 331px;
}
.img-circle-11{
  /* width: 97px; */
  right: 225px;
  top: 120px;
}
.img-circle-12{
  width: 170px;
  right: 40px;
  top: 150px;
}
.img-circle-13{
  width: 96px;
  right: 5px;
  top: 300px;
}
.menu-on-this-page {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1200px) {
  .presentationx {
    padding-top: 514px !important; 
  }
}


@media (max-width: 502px) {
  .bk-theme-2-change {
    top: -65px;
  }
  .content_img {
    width: 250% !important; 
  }
  .content_img_2 {
    width: 250% !important;
  }
  /* .presentation{
    padding-top: 465px !important; 
  } */
  .img-circle-1,
  .img-circle-2,
  .img-circle-3,
  .img-circle-4,
  .img-circle-5,
  .img-circle-6,
  .img-circle-7,
  .img-circle-8,
  .img-circle-9,
  .img-circle-10,
  .img-circle-11,
  .img-circle-12,
  .img-circle-13{
    position: absolute;
    z-index: 10;
  }
  
  .img-circle-1{
    /* width: 68px; */
    left: 5px;
    bottom: 100px;
  }
  /* Tamaños imagenes */
  .img-circle-1,
  .img-circle-2,
  .img-circle-3,
  .img-circle-5,
  .img-circle-6,
  .img-circle-8,
  .img-circle-11{
    width: 93px;
  }
  
  .img-circle-2{
    left: 100px;
    top: 180px;
  }
  .img-circle-3{
    left: 190px;
    bottom: 90px;
  }
  .img-circle-4{
    width: 124px;
    left: 190;
    top: 184px;
  }
  .img-circle-5{
    /* width: 87px; */
    left: 176px;
    top: 170px;
  }
  .img-circle-6{
    /* width: 68px; */
    left: 515px;
    top: 275px;
  }
  .img-circle-7{
    width: 90px;
    right: 575px;
    top: 160px;
  }
  .img-circle-8{
    /* width: 99px; */
    right: 475px;
    top: 325px;
  }
  .img-circle-9{
    width: 90px;
    right: 355px;
    top: 190px;
  }
  .img-circle-10{
    width: 100px;
    right: 185px;
    top: 271px;
  }
  .img-circle-11{
    /* width: 97px; */
    right: 225px;
    top: 160px;
  }
  .img-circle-12{
    width: 118px;
    right: 40px;
    top: 150px;
  }
  .img-circle-13{
    width: 79px;
    right: 15px;
    top: 300px;
  }
}